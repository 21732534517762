let audioIsPlaying = false;

$( document ).ready(function() {
    let player = $("audio")[0];

    // play/pause
    $(".audio-player").click(function() {
        playOrPause($(this));
    });

    // restarts the whole audio from the beginning
    $(this).find(".audio-player-text").click(function() {
        //let player = $("audio")[0];
        playOrPause($(this));
    });

});

// play or pause song
window.playOrPause = function(element) {
    audioelement = $(element).prev("audio");
    let player = audioelement[0];

    if ( player.paused ) {
        player.play();
        audioIsPlaying = true; 
        checkPlayerStatus(player, element);

        $(element).find(".audio-player-button").attr("src", "../images/Pause-Icon.svg");
    } else {
        player.pause();
        audioIsPlaying = false 

        $(element).find(".audio-player-button").attr("src", "../images/Play-Icon.svg")
    }
}

// resets the player, if the audio is over
function songended(player, element) {
    const progress = player.currentTime/player.duration;
    
    if ( progress == 1.0 ) {
        audioIsPlaying = false; 
        updateProgressUI(progress, element);

        $(element).find(".audio-player-button").attr("src", "../images/Play-Icon.svg") 

        $(element).find(".audio-player-progress-segment-1").css("display", "none");
        $(element).find(".audio-player-progress-segment-2").css("display", "none");
        $(element).find(".audio-player-progress-segment-3").css("display", "none");
        $(element).find(".audio-player-progress-segment-4").css("display", "none");
    }
}


// Checks, if player is playing and updates the UI accordingly, if so
function checkPlayerStatus(player, element) {    
    if ( audioIsPlaying ) {
        let progress = player.currentTime/player.duration;
        
        if (progress == 1.0) {
            songended(player, element);
        } else {
            updateProgressUI(progress, element);
        }

        setTimeout(function () {            
            checkPlayerStatus(player, element);
        }, 33);
    }
}

// updates the progress bar
function updateProgressUI(progress, element) {
    if ( progress < 0.25 ) {
        $(element).find(".audio-player-progress-segment-1").css("display", "block");

        let segmentProgress = -(90-progress*360.0);
        $(element).find(".audio-player-progress-segment-1").css("transform", "skew(0deg,"+segmentProgress+"deg)")
    } else if ( progress < 0.5 ) {
        $(element).find(".audio-player-progress-segment-1").css("transform", "skew(0deg, 0deg)")

        let segmentProgress = (180-progress*360.0);
        $(element).find(".audio-player-progress-segment-2").css("transform", "skew("+segmentProgress+"deg, 0deg)")
        $(element).find(".audio-player-progress-segment-2").css("display", "block");
    } else if ( progress < 0.75 ) {
        $(element).find(".audio-player-progress-segment-2").css("transform", "skew(0deg, 0deg)")

        let segmentProgress = -(270-progress*360.0);
        $(element).find(".audio-player-progress-segment-3").css("transform", "skew(0deg,"+segmentProgress+"deg)")
        $(element).find(".audio-player-progress-segment-3").css("display", "block");
    } else {
        $(element).find(".audio-player-progress-segment-3").css("transform", "skew(0deg, 0deg)")

        let segmentProgress = (360-progress*360.0);
        $(element).find(".audio-player-progress-segment-4").css("transform", "skew("+segmentProgress+"deg, 0deg)")
        $(element).find(".audio-player-progress-segment-4").css("display", "block");
    }
}
