/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (frontend.css in this case)
import '../styles/frontend.scss';
//import '../styles/circleaudio.css'; 
import '../styles/audio-player.css';

import * as Survey from "survey-jquery";

// start the Stimulus application
import 'bootstrap'; 

import $ from 'jquery';
import '../js/audio-player.js';
import validator from 'validator';

$(document).ready(function() {
    // Panel Signout Logic
    var user = {};
    $('#btnsignout').click(function() {
        user.id = $(this).attr("data");
        user.email = $(this).attr("email");

        $.post("/panel/usersignout", user, 
        function(data) {
            if (data.success == true) {
                $('#btnsignout').hide();
                $('.response').append("<p><b>Alles klar, wir haben dich erfolgreich abgemeldet.</b></p>");
            } else {
                alert('Es gab ein Problem beim Speichern');
            }
        });
    });

    // Panel Signin Logic
    $('#btnsignin').click(function() {
        var error = 0;
        if (validator.isEmpty($('#firstname').val())) {
            $('#firstname').addClass("alert-danger");
            error++;
        }
        if (validator.isEmpty($('#lastname').val())) {
            $('#lastname').addClass("alert-danger");
            error++;
        }
        if (!validator.isEmail($('#email').val())) {
            $('#email').addClass("alert-danger");
            error++;
        }
        if (error == 0) {
            var panel = {};
            panel.station = $('#station').val();
            panel.firstname = $('#firstname').val();
            panel.lastname = $('#lastname').val();
            panel.email = $('#email').val();
            panel.gender = $('#gender').val();
            panel.birthdate = $('#jahrgang').val();
            panel.canton = $('#wohnkanton').val();

            $.post("/panel/panelsignin", panel, 
            function(data) {
                if (data.erfolg == true) {
                    $('.signinform').hide();
                    $('.response').empty();
                    $('.response').append(data.message);
                } else {
                    $('.response').empty();
                    $('.response').append(data.message);
                }
            });
        } 

    })
});